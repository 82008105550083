<template>
  <!-- 菜单管理页面 -->
  <div class="padding24 box">
    <div v-show="$route.meta.isShow">
      <!-- 顶部 -->
      <div class="top">
        <div class="top_left">
          <a-button icon="plus" type="primary" @click="addMenu">
            新建菜单
          </a-button>
        </div>
        <div class="top_rigiht">
          <span>菜单名字</span>
          <a-input
            ref="userNameInput"
            v-model="searchVal"
            placeholder="请输入关键字"
            style="width: 150px"
          >
            <a-icon slot="prefix" type="search" />
          </a-input>
          <a-button
            icon="search"
            type="primary"
            @click="searchClick"
            :disabled="isDisableSearch"
            >搜索</a-button
          >
        </div>
      </div>
      <a-table
        :columns="columns"
        :data-source="tabledData"
        :pagination="false"
        :loading="tableLoading"
      >
        <span slot="displayStatus" slot-scope="displayStatus, record">
          <span>{{ record.displayStatus == 0 ? "是" : "否" }}</span>
        </span>
        <span slot="operation" slot-scope="operation, record">
          <a class="margin_right10" @click="editClick(record)">编辑</a>
          <a @click="del(record.key)">删除</a>
        </span>
      </a-table>
    </div>
    <router-view />
  </div>
</template>

<script>
import { Modal } from "ant-design-vue";
import { message } from "ant-design-vue";
import { MenuTreeApi, MenuDeleteApi } from "@/request/api/systemManage";
export default {
  created() {
    this.menuListFn();
  },
  watch: {
    $route(to) {
      if (to.path === "/systemManage/menuManage") {
        this.menuListFn();
      }
    },
  },
  data() {
    return {
      tableLoading: true, // 表格的loading图
      searchVal: "", // 输入框的值
      isDisableSearch: false, // 搜索按钮是否禁用
      columns: [
        {
          title: "菜单名字",
          dataIndex: "title",
          key: "title",
          width: "25%",
        },
        {
          title: "跳转链接",
          dataIndex: "url",
          width: "30%",
          key: "url",
        },
        {
          title: "是否显示",
          dataIndex: "displayStatus",
          width: "10%",
          key: "displayStatus",
          scopedSlots: { customRender: "displayStatus" },
        },
        {
          title: "操作",
          dataIndex: "operation",
          width: "30%",
          key: "operation",
          scopedSlots: { customRender: "operation" },
        },
      ],
      tabledData: [],
    };
  },
  methods: {
    // 搜索按钮 , 回车搜索
    searchClick() {
      this.isDisableSearch = true;
      setTimeout(() => {
        this.isDisableSearch = false;
      }, 3000);
      this.menuListFn();
    },
    // 添加菜单按钮
    addMenu() {
      this.$router.push(`/systemManage/menuManage/editMenu/0`);
    },
    // 删除的按钮
    del(key) {
      let that = this;
      Modal.confirm({
        title: "提示",
        content: "删除后相关数据无法恢复,是否确认删除",
        okText: "确认",
        cancelText: "取消",
        onOk() {
          MenuDeleteApi({ id: key }).then((res) => {
            if (res.code === 200) {
              message.success("删除成功", 3);
              let tabledData = that.tabledData;
              that.tabledData = tabledData.filter((item) => item.key !== key);
            }
          });
        },
      });
    },
    editClick(data) {
      this.$router.push(`/systemManage/menuManage/editMenu/${data.key}`);
    },
    // 请求菜单列表数据
    menuListFn() {
      MenuTreeApi().then(({ code, data }) => {
        if (code === 200) {
          this.tableLoading = false;
          let newData = data.map((item) => {
            return JSON.parse(JSON.stringify(item).replace(/id/g, "key"));
          });
          this.tabledData = newData;
        }
      });
    },
  },
};
</script>
 
<style lang="less" scoped>
.box {
  min-height: 100%;
  background-color: #fff;
  padding-bottom: 56px;
}
.top {
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  .top_rigiht {
    span {
      font-size: 16px;
      margin-right: 10px;
      font-size: 600;
    }
    .ant-btn {
      margin-left: 10px;
    }
  }
}
/deep/ .ant-checkbox {
  display: none;
}
/deep/.ant-table-thead > tr > th {
  font-weight: 600;
}
</style>